export const styles = theme => ({
  container: {
    borderColor: theme.colors.neutral.level9,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 5,
    background: theme.colors.neutral.white,
    margin: 0,
    width: `calc(100% - ${theme.spacing(6)}px)`,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      marginBottom: theme.spacing(2)
    }
  },
  title: {
    fontFamily: theme.fonts.fontPrimary,
    fontWeight: theme.fonts.medium,
    color: theme.colors.neutral.level3,
    fontSize: 16,
    hyphens: 'auto',
    marginBottom: 8
  },
  subTitle: {
    color: theme.colors.neutral.level3,
    fontSize: 14,
    fontFamily: theme.fonts.fontSecondary,
    margin: 0
  },
  notes: {
    fontFamily: theme.fonts.fontSecondary,
    fontWeight: theme.fonts.regular,
    color: theme.colors.neutral.level4,
    fontSize: 12
  },
  headerWrapper: {
    display: 'flex',
    flexDiretion: 'row',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(3)}px 0`
  },
  rankingAlign: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});

export default styles;
