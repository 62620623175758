import {Grid, withStyles} from '@material-ui/core';
import {push} from 'connected-react-router';
import {filter, get, map, sortBy} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isMobile} from '../app/config';
import NavbarContext from '../app/context/NavbarContext';
import {CURRENTDATE} from '../app/filters/component/dateFilter/dateUtil';
import {getUserPanels, getPanelCharts, savePanel, deletePanel, ALL_GRAPHS_PANEL_ID} from './action';
import chartsMap from './chartsData';
import EditingPanel from './editingPanel';
import MainPanel from './mainPanel';
import styles from './styles';

const emptyPanel = {
  id: '',
  name: '',
  charts: []
};

class SalesDashboard extends React.Component {
  static contextType = NavbarContext;
  state = {
    editingMode: false, // Indicates if editing panel is open
    lastPanel: null, // Panel that the user was seeing before entering editing mode
    loadedPanels: {}, // Already opened panels, to avoid hitting API many times for same panels
    loading: false, // General loading
    loadingError: false, // Indicates general loading error
    loadingCharts: false, // Indicates whether current panel charts are loading
    loadingChartsError: false, // Indicates if the charts from current panel finished with error
    loadingDelete: false, // Indicates if current panel is being deleted
    loadingDeleteError: false, // Indicates if current panel delete finished with error
    loadingSave: false, // Indicates if current panel is being saved
    loadingSaveError: false, // Indicates if current panel save finished with error
    currentPanel: null, // Currently selected panel
    userPanels: [] // List containing ids and names of user panels.
  };

  componentDidMount() {
    this.context.configure({
      print: this.props.doPrint,
      filters: {
        dateFilter: CURRENTDATE,
        enableCities: true,
        enableFlags: true,
        enableRegional: true,
        enableStates: true,
        enableStores: true,
        enableStoreType: true,
        enablePresetFilter: true,
        enableShowClosedStores: true
      }
    });
    this.loadUserPanels();
  }

  goToOldDashboardVersion = () => {
    this.props.push('/operacional/painel');
  };

  getChartsArray = () => {
    return map(chartsMap(), (v, k) => ({id: k, ...v}));
  };

  // Actions
  loadUserPanels = async () => {
    this.setState({loading: true, loadingError: false});
    await getUserPanels()
      .then(panels => {
        const newState = {
          loading: false,
          userPanels: panels,
          currentPanel: get(panels, '0', null)
        };
        this.setState(newState, this.loadPanelCharts);
      })
      .catch(e => {
        if (e.message !== 'cancel') {
          this.setState({loading: false, loadingError: true});
        }
      });
  };

  loadPanelCharts = async () => {
    const {currentPanel, loadedPanels} = this.state;
    if (!currentPanel) return;

    const loadedPanel = get(loadedPanels, currentPanel.id);
    if (loadedPanel) {
      return this.setState({loadingCharts: false, loadingChartsError: false, currentPanel: loadedPanel});
    }

    this.setState({loadingCharts: true, loadingChartsError: false});
    await getPanelCharts(currentPanel.id)
      .then(panel => {
        if (panel.id === this.state.currentPanel.id) {
          // Usual case
          this.setState({loadingCharts: false, currentPanel: panel, loadedPanels: {...this.state.loadedPanels, [panel.id]: panel}});
        } else if (!this.state.currentPanel.id) {
          // When editing something
          this.setState({loadingCharts: false, loadedPanels: {...this.state.loadedPanels, [panel.id]: panel}});
        } else {
          // Selected panel changed before finish loading
          this.setState({loadedPanels: {...this.state.loadedPanels, [panel.id]: panel}});
        }
      })
      .catch(e => {
        if (e.message !== 'cancel') {
          this.setState({loadingCharts: false, loadingChartsError: true});
        }
      });
  };

  handleSave = async () => {
    const {currentPanel, userPanels} = this.state;
    this.setState({loadingSave: true, loadingSaveError: false, loadingDeleteError: false});
    await savePanel(currentPanel)
      .then(panelId => {
        this.setState({
          editingMode: false,
          loadingSave: false,
          lastPanel: null,
          currentPanel: {...currentPanel, id: panelId},
          userPanels: sortBy([...filter(userPanels, it => it.id !== currentPanel.id), {id: panelId, name: currentPanel.name}], ['name']),
          loadedPanels: {...this.state.loadedPanels, [panelId]: {...currentPanel, id: panelId}}
        });
      })
      .catch(() => this.setState({loadingSave: false, loadingSaveError: true}));
  };

  handleDelete = async () => {
    const {currentPanel, userPanels} = this.state;
    this.setState({loadingDelete: true, loadingDeleteError: false, loadingSaveError: false});
    await deletePanel(currentPanel.id)
      .then(() => {
        const remainingPanels = filter(userPanels, it => it.id !== currentPanel.id);
        const newState = {
          editingMode: false,
          loadingDelete: false,
          lastPanel: null,
          userPanels: remainingPanels,
          currentPanel: get(remainingPanels, '0', null)
        };
        this.setState(newState, this.loadPanelCharts);
      })
      .catch(() => this.setState({loadingDelete: false, loadingDeleteError: true}));
  };

  // Main Panel
  addPanel = () => {
    const {currentPanel} = this.state;
    this.setState({editingMode: true, currentPanel: emptyPanel, lastPanel: currentPanel});
  };

  editPanel = () => {
    const {currentPanel} = this.state;
    this.setState({editingMode: true, lastPanel: currentPanel});
  };

  handlePanelSelect = panel => {
    const {currentPanel} = this.state;
    if (currentPanel.id !== panel.id) {
      this.setState({currentPanel: panel}, this.loadPanelCharts);
    }
  };

  // Editing Panel
  handleAddChart = chart => {
    const {currentPanel} = this.state;
    const {charts} = currentPanel;
    this.setState({currentPanel: {...currentPanel, charts: [...charts, chart]}});
  };

  handleCancel = () => {
    const {lastPanel, loadedPanels} = this.state;
    this.setState({editingMode: false, loadingSaveError: false, loadingDeleteError: false, currentPanel: get(loadedPanels, get(lastPanel, 'id'), lastPanel), lastPanel: null});
  };

  handleChangeCharts = charts => {
    const {currentPanel} = this.state;
    this.setState({currentPanel: {...currentPanel, charts: map(charts, chart => ({id: chart.id, name: chart.content}))}});
  };

  handleChangeName = name => {
    const {currentPanel} = this.state;
    this.setState({currentPanel: {...currentPanel, name: name}});
  };

  render() {
    const {isMobile} = this.props;
    const {editingMode, loading, loadingError, loadingCharts, loadingChartsError, loadingDelete, loadingDeleteError, loadingSave, loadingSaveError, currentPanel, userPanels} = this.state;
    const disableEdition = !!(isMobile || (currentPanel && currentPanel.id === ALL_GRAPHS_PANEL_ID));
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={editingMode ? 8 : 12}>
              <MainPanel
                disableNewPanel={isMobile}
                disableEdition={disableEdition}
                editingMode={editingMode}
                addPanel={this.addPanel}
                editPanel={this.editPanel}
                reloadPanels={this.loadUserPanels}
                reloadCharts={this.loadPanelCharts}
                isMobile={isMobile}
                onChangePanel={this.handlePanelSelect}
                currentPanel={currentPanel}
                userPanels={userPanels}
                loading={loading}
                loadingError={loadingError}
                loadingCharts={loadingCharts}
                loadingChartsError={loadingChartsError}
              />
            </Grid>
            {!isMobile && editingMode && (
              <Grid item xs={4}>
                <EditingPanel
                  availableCharts={this.getChartsArray()}
                  handleAddChart={this.handleAddChart}
                  handleCancel={this.handleCancel}
                  handleChangeCharts={this.handleChangeCharts}
                  handleChangeName={this.handleChangeName}
                  handleDelete={this.handleDelete}
                  handleSave={this.handleSave}
                  loading={loading}
                  loadingSave={loadingSave}
                  loadingSaveError={loadingSaveError}
                  loadingDelete={loadingDelete}
                  loadingDeleteError={loadingDeleteError}
                  panelData={currentPanel}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

SalesDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  doPrint: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isMobile: isMobile(state)
});

export default connect(mapStateToProps, {push})(withStyles(styles)(SalesDashboard));
