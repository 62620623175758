import {t} from '@lingui/macro';
import loadable from '@loadable/component';
import React from 'react';

import Loading from '../app/Loading';

const WATERFALL_RANKING_REGIONAL_STORE_SALE = 'waterfall.ranking.regional.store.sale';
const WATERFALL_RANKING_REGIONAL_STORE_SALE_ACC = 'waterfall.ranking.regional.store.sale.acc';

const WATERFALL_RANKING_REGIONAL_STORE_SALE_COMPARISON = 'waterfall.ranking.regional.store.sale.comparison';
const WATERFALL_RANKING_REGIONAL_STORE_SALE_COMPARISON_ACC = 'waterfall.ranking.regional.store.sale.comparison.acc';

const WATERFALL_RANKING_FAMILY_DEPARTMENT_SALE = 'waterfall.ranking.family.department.sale';
const WATERFALL_RANKING_FAMILY_DEPARTMENT_SALE_ACC = 'waterfall.ranking.family.department.sale.acc';

const STACKED_BARS_FAMILY_SALE = 'stacked.bars.family.sale';
const STACKED_BARS_FAMILY_SALE_ACC = 'stacked.bars.family.sale.acc';

const BARS_PIECES_SALE_COMPARISON_MONTHLY = 'bars.pieces.sale.comparison.monthly';

const UMBRELLA_REGIONAL_SALE_GOAL_ACC = 'umbrella.regional.sale.goal.acc';
const UMBRELLA_FAMILY_SALE_GOAL_ACC = 'umbrella.family.sale.goal.acc';

const WATERFALL_FINANCIAL_PRODUCTS = 'waterfall.financial.products';
const WATERFALL_FINANCIAL_PRODUCTS_ACC = 'waterfall.financial.products.acc';

const WATERFALL_FAMILY_STOCK = 'waterfall.family.stock';
const WATERFALL_FAMILY_STOCK_ACC = 'waterfall.family.stock.acc';

const BARS_RANKING_AV_CARD_REGIONAL_STORE_GOAL = 'bars.ranking.av.card.regional.store.goal';
const BARS_RANKING_AV_CARD_REGIONAL_STORE_GOAL_ACC = 'bars.ranking.av.card.regional.store.goal.acc';

const BARS_RANKING_PCJ_REGIONAL_STORE_GOAL = 'bars.ranking.pcj.regional.store.goal';
const BARS_RANKING_PCJ_REGIONAL_STORE_GOAL_ACC = 'bars.ranking.pcj.regional.store.goal.acc';

const WATERFALL_RANKING_ATIVADOS_REGIONAL_STORE_GOAL = 'waterfall.ranking.ativados.regional.store.goal';
const WATERFALL_RANKING_ATIVADOS_REGIONAL_STORE_GOAL_ACC = 'waterfall.ranking.ativados.regional.store.goal.acc';

const BARS_RANKING_AVERAGE_TICKET_REGIONAL_STORE_GOAL = 'bars.ranking.average.ticket.regional.store.goal';
const BARS_RANKING_AVERAGE_TICKET_REGIONAL_STORE_GOAL_ACC = 'bars.ranking.average.ticket.regional.store.goal.acc';

const BARS_RANKING_PIECES_PER_COUPON_REGIONAL_STORE = 'bars.ranking.pieces.per.coupon.regional.store';
const BARS_RANKING_PIECES_PER_COUPON_REGIONAL_STORE_ACC = 'bars.ranking.pieces.per.coupon.regional.store.acc';

const BARS_DAILY_STOCK_COMPARISON_MONTH = 'bars.daily.stock.comparison.month';

const BARS_COMPARATIVE_SALE_DETAILED = 'bars.comparative.sale.detailed';

const BARS_PIECES_PER_COUPON_31DAYS = 'bars.pieces.per.coupon.31days';
const BARS_ISSUED_COUPON_31DAYS = 'bars.issued.coupon.31days';
const BARS_AVERAGE_TICKET_31DAYS = 'bars.average.ticket.31days';

const CHARTS_MAP = () => {
  return {
    [WATERFALL_RANKING_REGIONAL_STORE_SALE]: {
      name: t`salesDashboard.charts.waterfallRankingRegionalStoreSale`,
      component: loadable(() => import('./charts/WaterfallRankingRegionalStoreSale/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallAndRankingChart.svg`
    },
    [WATERFALL_RANKING_REGIONAL_STORE_SALE_ACC]: {
      name: t`salesDashboard.charts.waterfallRankingRegionalStoreSaleAcc`,
      component: loadable(() => import('./charts/WaterfallRankingRegionalStoreSale/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallAndRankingChart.svg`
    },
    [WATERFALL_RANKING_REGIONAL_STORE_SALE_COMPARISON]: {
      name: t`salesDashboard.charts.waterfallRankingRegionalStoreSaleComparison`,
      component: loadable(() => import('./charts/WaterfallRankingRegionalStoreSaleComparison/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallAndRankingChart.svg`
    },
    [WATERFALL_RANKING_REGIONAL_STORE_SALE_COMPARISON_ACC]: {
      name: t`salesDashboard.charts.waterfallRankingRegionalStoreSaleComparisonAcc`,
      component: loadable(() => import('./charts/WaterfallRankingRegionalStoreSaleComparison/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallAndRankingChart.svg`
    },
    [BARS_COMPARATIVE_SALE_DETAILED]: {
      name: t`salesDashboard.charts.comparativeSaleDetailed`,
      component: loadable(() => import('./charts/BarsComparativeSaleDetailed/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsComparativeSaleDetailedChart.png`
    },
    [UMBRELLA_REGIONAL_SALE_GOAL_ACC]: {
      name: t`saleDashboard.charts.umbrellaRegionalSaleGoalAcc`,
      component: loadable(() => import('./charts/UmbrellaRegionalSaleGoal/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/UmbrellaChart.svg`
    },
    [WATERFALL_RANKING_FAMILY_DEPARTMENT_SALE]: {
      name: t`saleDashboard.charts.waterfallRankingFamilyDepartmentSale`,
      component: loadable(() => import('./charts/_old/WaterfallRankingFamilyDepartmentSale'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallAndRankingChart.svg`
    },
    [WATERFALL_RANKING_FAMILY_DEPARTMENT_SALE_ACC]: {
      name: t`salesDashboard.charts.waterfallRankingFamilyDepartmentSaleAcc`,
      component: loadable(() => import('./charts/_old/WaterfallRankingFamilyDepartmentSaleAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallAndRankingChart.svg`
    },
    [UMBRELLA_FAMILY_SALE_GOAL_ACC]: {
      name: t`salesDashboard.charts.umbrellaFamilySaleGoalAcc`,
      component: loadable(() => import('./charts/UmbrellaFamilySaleGoal/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/UmbrellaChart.svg`
    },
    [STACKED_BARS_FAMILY_SALE]: {
      name: t`saleDashboard.charts.stackedBarsFamilySale`,
      component: loadable(() => import('./charts/StackedBarsFamilySale/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/StackedBarsChart.svg`
    },
    [STACKED_BARS_FAMILY_SALE_ACC]: {
      name: t`saleDashboard.charts.stackedBarsFamilySaleAcc`,
      component: loadable(() => import('./charts/StackedBarsFamilySale/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/StackedBarsChart.svg`
    },
    [BARS_RANKING_AVERAGE_TICKET_REGIONAL_STORE_GOAL]: {
      name: t`salesDashboard.charts.barsRankingAverageTicketRegionalStoreGoal`,
      component: loadable(() => import('./charts/BarsAverageTicketRegionalStoreGoal/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsAndRankingChart.svg`
    },
    [BARS_RANKING_AVERAGE_TICKET_REGIONAL_STORE_GOAL_ACC]: {
      name: t`salesDashboard.charts.barsRankingAverageTicketRegionalStoreGoalAcc`,
      component: loadable(() => import('./charts/BarsAverageTicketRegionalStoreGoal/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsAndRankingChart.svg`
    },
    [BARS_RANKING_PIECES_PER_COUPON_REGIONAL_STORE]: {
      name: t`salesDashboard.charts.barsRankingPiecesPerCouponRegionalStore`,
      component: loadable(() => import('./charts/BarsPiecesPerCouponRegionalStore/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsAndRankingChart.svg`
    },
    [BARS_RANKING_PIECES_PER_COUPON_REGIONAL_STORE_ACC]: {
      name: t`salesDashboard.charts.barsRankingPiecesPerCouponRegionalStoreAcc`,
      component: loadable(() => import('./charts/BarsPiecesPerCouponRegionalStore/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsAndRankingChart.svg`
    },
    [BARS_AVERAGE_TICKET_31DAYS]: {
      name: t`saleDashboard.charts.barsAverageTicket31days`,
      component: loadable(() => import('./charts/BarsAverageTicket31Days/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsPiecesChart.svg`
    },
    [BARS_PIECES_PER_COUPON_31DAYS]: {
      name: t`saleDashboard.charts.barsPiecesPerCoupon31days`,
      component: loadable(() => import('./charts/BarsPiecesPerCoupon31Days/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsPiecesChart.svg`
    },
    [BARS_ISSUED_COUPON_31DAYS]: {
      name: t`saleDashboard.charts.barsIssuedCoupon31days`,
      component: loadable(() => import('./charts/BarsIssuedCoupon31Days/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsPiecesChart.svg`
    },
    [BARS_PIECES_SALE_COMPARISON_MONTHLY]: {
      name: t`saleDashboard.charts.barsPiecesSaleComparisonMonthly`,
      component: loadable(() => import('./charts/_old/BarsPiecesSaleComparisonMonthly'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsPiecesChart.svg`
    },
    [WATERFALL_RANKING_ATIVADOS_REGIONAL_STORE_GOAL]: {
      name: t`salesDashboard.charts.waterfallRankingAtivadosRegionalStoreGoal`,
      component: loadable(() => import('./charts/WaterfallAtivadosRegionalStoreGoal/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallAndRankingChart.svg`
    },
    [WATERFALL_RANKING_ATIVADOS_REGIONAL_STORE_GOAL_ACC]: {
      name: t`salesDashboard.charts.waterfallRankingAtivadosRegionalStoreGoalAcc`,
      component: loadable(() => import('./charts/WaterfallAtivadosRegionalStoreGoal/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallAndRankingChart.svg`
    },
    [BARS_RANKING_AV_CARD_REGIONAL_STORE_GOAL]: {
      name: t`salesDashboard.charts.barsRankingAvCardRegionalStoreGoal`,
      component: loadable(() => import('./charts/BarsAvCardRegionalStoreGoal/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsAndRankingChart.svg`
    },
    [BARS_RANKING_AV_CARD_REGIONAL_STORE_GOAL_ACC]: {
      name: t`salesDashboard.charts.barsRankingAvCardRegionalStoreGoalAcc`,
      component: loadable(() => import('./charts/BarsAvCardRegionalStoreGoal/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsAndRankingChart.svg`
    },
    [BARS_RANKING_PCJ_REGIONAL_STORE_GOAL]: {
      name: t`salesDashboard.charts.barsRankingPcjRegionalStoreGoal`,
      component: loadable(() => import('./charts/BarsPcjRegionalStoreGoal/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsAndRankingChart.svg`
    },
    [BARS_RANKING_PCJ_REGIONAL_STORE_GOAL_ACC]: {
      name: t`saleDashboard.charts.barsRankingPcjRegionalStoreGoalAcc`,
      component: loadable(() => import('./charts/BarsPcjRegionalStoreGoal/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsAndRankingChart.svg`
    },
    [WATERFALL_FINANCIAL_PRODUCTS]: {
      name: t`salesDashboard.charts.waterfallFinancialProducts`,
      component: loadable(() => import('./charts/WaterfallFinancialProducts/chartDaily'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallChart.svg`
    },
    [WATERFALL_FINANCIAL_PRODUCTS_ACC]: {
      name: t`salesDashboard.charts.waterfallFinancialProductsAcc`,
      component: loadable(() => import('./charts/WaterfallFinancialProducts/chartAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallChart.svg`
    },
    [WATERFALL_FAMILY_STOCK]: {
      name: t`salesDashboard.charts.waterfallFamilyStock`,
      component: loadable(() => import('./charts/_old/WaterfallFamilyStock'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallChart.svg`
    },
    [WATERFALL_FAMILY_STOCK_ACC]: {
      name: t`salesDashboard.charts.waterfallFamilyStockAcc`,
      component: loadable(() => import('./charts/_old/WaterfallFamilyStockAcc'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/WaterfallChart.svg`
    },
    [BARS_DAILY_STOCK_COMPARISON_MONTH]: {
      name: t`salesDashboard.charts.barsDailyStockComparisonMonth`,
      component: loadable(() => import('./charts/_old/BarsDailyStockComparisonMonth'), {fallback: <Loading />}),
      image: `${process.env.PUBLIC_URL}/imgs/charts/thumbnails/BarsDailyStockChart.svg`
    }
  };
};

export default CHARTS_MAP;
