import Loadable from '@loadable/component';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router';

import AdminRoute from '../admin/adminRoute';
import Analitico from '../analytics';
import NavbarContextProvider from '../app/context/NavbarContextProvider';
import ErrorHandler from '../app/errorHandler';
import Loading from '../app/Loading';
import ProtectedRoute from '../app/protectedRoute';
import AreaSales from '../areaSales';
import BSC from '../bsc';
import CostRanking from '../costRanking';
import DailySales from '../dailysales/component';
import DailyShopping from '../dailyshopping/component';
import DRE from '../dre';
import FinancialProducts from '../financialProducts';
import Goals from '../goals';
import HolidayComponent from '../holiday';
import Login from '../login';
import NewStoresComponent from '../newStores';
import NPSCommentComponent from '../nps/comments';
import NPSDashboardComponent from '../nps/dashboard';
import PopQuiz from '../pop/PopQuiz';
import PopQuizForm from '../pop/PopQuizForm';
import ProductivityComponent from '../productivity';
import RankingComparativo from '../ranking/component/comparative';
import FamilyRanking from '../ranking/component/family';
import MobilePdv from '../ranking/component/pdv';
import RankingComparativoDashboard from '../ranking/dashboard';
import ReleaseNotes from '../releaseNotes';
import SalesDashboardV2 from '../salesDashboard-v2';
import Schedule from '../schedule';
import ScheduleDashboard from '../schedule/ScheduleDashboard';
import PontoDePartida from '../startingPoint';
import StoresHours from '../StoresHours';
import TopSales from '../topsales/container';
import AverageTicket from '../tudoav/averageTicket';
import MonthlyChart from '../tudoav/monthlyChart';
import Registrations from '../tudoav/registrations';
import Transactions from '../tudoav/transactions';

const Admin = Loadable(() => import('../admin/Admin'), {
  fallback: <Loading />
});
class App extends Component {
  render() {
    return (
      <div className="noPrint">
        <NavbarContextProvider>
          <ErrorHandler>
            <Switch>
              <ProtectedRoute path="/" exact component={() => <Redirect to={{pathname: '/ranking/comparativo/'}} />} />
              <ProtectedRoute path="/ranking/comparativo/dashboard/:type(bandeira|cidade|estado|regional|filial|familia)?/:item?" component={RankingComparativoDashboard} hideNavigation />
              <ProtectedRoute path="/ranking/comparativo/:type(bandeira|cidade|estado|regional|filial|familia)?/:item?" component={RankingComparativo} />
              <ProtectedRoute path="/ranking/familias/:type(bandeira|cidade|estado|regional|filial)?/:item?" component={FamilyRanking} />
              <ProtectedRoute path="/ranking/pdvmovel/:type(bandeira|cidade|estado|regional|filial)?/:item?/:storeCode?" component={MobilePdv} />
              <ProtectedRoute path="/operacional/pontodepartida/:type(bandeira|cidade|estado|regional|filial|total)?/:loja?" component={PontoDePartida} />
              <ProtectedRoute
                path="/operacional/vendas-dia-a-dia/:route(venda|projecoes|compromisso|compromisso-diario|ranking-telefonia)?/:type(bandeira|cidade|estado|regional|filial)?/:item?"
                component={DailySales}
              />
              <ProtectedRoute path="/operacional/compras-dia-a-dia/:route?/:type?/:item?/:storeCode?" component={DailyShopping} />
              <ProtectedRoute path="/operacional/produtos-financeiros/:type?/:item?" component={FinancialProducts} />
              <ProtectedRoute path="/operacional/top-vendas" component={TopSales} />
              <ProtectedRoute
                path="/operacional/produtividade/:route(ranking|funcionarios|detalhamento|lotacionograma)?/:type(bandeira|cidade|estado|regional|filial)?/:item?"
                component={ProductivityComponent}
              />
              <ProtectedRoute path="/operacional/resumo-feriados/:item?" component={HolidayComponent} />
              <ProtectedRoute path="/operacional/horario-lojas" component={StoresHours} />
              <ProtectedRoute path="/operacional/painel-v2" component={SalesDashboardV2} />
              <ProtectedRoute path="/operacional/prova-pop/preenchimento/:id" component={PopQuizForm} />
              <ProtectedRoute path="/operacional/prova-pop/:type(bandeira|cidade|estado|regional|filial)?" component={PopQuiz} />
              <ProtectedRoute path="/operacional/area-venda/:type(bandeira|cidade|estado|regional|filial)?/:item?" component={AreaSales} />
              <ProtectedRoute path="/operacional/tudoav/grafico-mensal" component={MonthlyChart} />
              <ProtectedRoute path="/operacional/tudoav/ticket-medio/:type(bandeira|cidade|estado|regional|filial)?/:item?" component={AverageTicket} />
              <ProtectedRoute path="/operacional/tudoav/cadastros/:type(bandeira|cidade|estado|regional|filial)?" component={Registrations} />
              <ProtectedRoute path="/operacional/tudoav/transacoes/:type(bandeira|cidade|estado|regional|filial)?/:item?" component={Transactions} />
              <ProtectedRoute path="/operacional/tudoav/grafico-mensal" component={MonthlyChart} />
              <ProtectedRoute path="/operacional/escala/painel/:type(bandeira|cidade|estado|regional|filial)?/:item?" component={ScheduleDashboard} />
              <ProtectedRoute path="/operacional/escala/preenchimento/:storeCode?/:date?" component={Schedule} />
              <ProtectedRoute path="/produtos-financeiros/lojas-novas/:type(bandeira|cidade|estado|regional|filial|familia)?/:item?" component={NewStoresComponent} />
              <ProtectedRoute path="/dre/dre/:type(bandeira|regional|filial|total)?/:item?" component={DRE} />
              <ProtectedRoute path="/dre/bsc/:type(bandeira|regional|filial|total)?/:item?" component={BSC} />
              <ProtectedRoute path="/dre/analitico" component={Analitico} />
              <ProtectedRoute path="/dre/ranking-de-custos/:type(bandeira|cidade|estado|regional|filial)?/:item?" component={CostRanking} />
              <ProtectedRoute path="/compromissos/:type(bandeira|cidade|estado|regional|filial)?/:item?/:storeCode?" component={Goals} />
              <ProtectedRoute path="/nps/painel/:type(bandeira|cidade|estado|regional|filial)?/:item?" component={NPSDashboardComponent} />
              <ProtectedRoute path="/nps/comentarios" component={NPSCommentComponent} />
              <ProtectedRoute path="/release-notes" component={ReleaseNotes} hideNavigation />
              <AdminRoute path="/admin" component={Admin} />
              <Route component={Login} />
            </Switch>
          </ErrorHandler>
        </NavbarContextProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({...state.router.location});

const connectedApp = connect(mapStateToProps)(App);
export default connectedApp;
