import Dialog from '@gav/dialog';
import Typography from '@gav/typography';
import {Trans} from '@lingui/react';
import {isBefore} from 'date-fns';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

const limitDate = new Date(2025, 1, 1);

const FootwearCampaingDialog = ({isMobile}) => {
  const [dialog, setDialog] = useState(false);
  const cookieName = 'footwearCampaingShown';

  const handleCloseDialog = () => {
    setDialog(false);
    Cookies.set(cookieName, 'true', {expires: 0.25});
  };

  useEffect(() => {
    const cookieExists = Cookies.get(cookieName);
    if (!cookieExists && isBefore(new Date(), limitDate)) {
      setDialog(true);
    }
  }, []);

  return (
    <Dialog
      isMobile={isMobile}
      fullScreen={isMobile}
      open={dialog}
      onClose={handleCloseDialog}
      primaryButtonAction={handleCloseDialog}
      title={
        <Typography size={18} weight="medium" lineHeight={22}>
          Cartão Avenida | Campanha no ar!
        </Typography>
      }
      primaryButtonText={<Trans id="common.action.close" />}
    >
      <a href="https://grupoavenida.sharepoint.com/:f:/s/Lojas_CAV/EgPgx6Mb6qdOhSfJT5KWfOMBXaHjX8CVReZvIexLHw2IIw?e=JCWahA" rel="noreferrer" target="_blank">
        <img style={{width: '100%', objectFit: 'cover'}} src={`${process.env.PUBLIC_URL}/imgs/campanha/cavVidaMais.jpg`} alt="Cartão AV" />
      </a>
    </Dialog>
  );
};

FootwearCampaingDialog.propTypes = {
  isMobile: PropTypes.bool
};

const mapStateToProps = state => ({
  isMobile: state.configuration.isMobile
});

export default connect(mapStateToProps)(FootwearCampaingDialog);
